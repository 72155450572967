import request from '@/util/request';

const PATH = '/news-statistics';

export const getWeatherTypeChartDatas = (searchQuery: any) => request({
  url: `${PATH}/weather`,
  method: 'get',
  params: {
    ...searchQuery,
  },
});

export const getAreaChartDatas = (searchQuery: any) => request({
  url: `${PATH}/area`,
  method: 'get',
  params: {
    ...searchQuery,
  },
});






import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  name: 'BarChart',
})
export default class extends Vue {
  @Prop({ required: true }) private elId!: any;

  @Prop({ required: true }) private data!: any;

  @Prop({ required: true }) private labels!: any;

  @Watch('data', { deep: true })
  private handleChangeChartData() {
    this.createChart({
      labels: this.labels,
      datasets: this.data,
    });
  }

  mounted() {
    this.createChart({
      labels: this.labels,
      datasets: [...this.data],
    });
  }

  private chart: any = null;

  /* eslint-disable */
  private options = {
    color: '#cdcdcd',
    beginAtZero: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
      },
    },
    scales: {
      title: { display: false },
      y: {
        grid: {
          color: '#566d7a',
        },
        ticks: {
          beginAtZero: true,
          callback: (value: any) => { if (Number.isInteger(value)) return value; },
          color: '#cdcdcd',
        },
      },
      x: {
        grid: {
          color: '#566d7a',
        },
        ticks: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  /* eslint-enable */

  private createChart(chartData: object) {
    const canvas = document.getElementById(this.elId) as HTMLCanvasElement;
    const options = {
      type: 'bar',
      data: chartData,
      options: this.options,
    };
    if (this.chart) this.chart.destroy();
    this.chart = new Chart(canvas, options as any);
  }
}

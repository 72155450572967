







































































import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { getAreaChartDatas, getWeatherTypeChartDatas } from '@/api/news-statistics';
import BarChart from './components/BarChart.vue';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: 'Statistics',
  components: {
    BarChart,
  },
})
export default class extends Vue {
  mounted() {
    this.initChart();
  }

  private getSearchDate() {
    if (this.searchedQuery.mode === 2) return `${this.searchedQuery.year}년`;
    if (this.searchedQuery.mode === 3) return `${this.searchedQuery.daterange[0]} ~ ${this.searchedQuery.daterange[1]}`;
    return '전체 기간';
  }

  private chartLoading = true;

  private searchQuery = {
    mode: 1,
    year: moment().format('YYYY'),
    daterange: [moment().format('YYYY-01-01'), moment().format('YYYY-MM-DD')],
  };

  private searchedQuery = {
    mode: 1,
    year: moment().format('YYYY'),
    daterange: [moment().format('YYYY-01-01'), moment().format('YYYY-MM-DD')],
  }

  private chartData: any = {
    typeLabels: [],
    typeDatas: [],
    areaLabels: [],
    areaDatas: [],
  }

  private brightColors = [
    '#02A4D3', '#4997D0', '#339ACC', '#00CCCC', '#8FD8D8', '#6CDAE7', '#93CCEA', '#9DE093', '#63B76C', '#5FA777', '#93DFB8', '#33CC99', '#8DD9CC',
    '#7070CC', '#ACACE6', '#6456B7', '#8B72BE', '#C9A0DC', '#FC74FD', '#EBB0D7', '#FD0E35', '#FF5349', '#FF9980', '#FF7F49', '#FF681F', '#FFAE42',
    '#F8D568', '#FFEB00', '#D9E650', '#BEE64B', '#FBAED2', '#FC80A5',
  ];

  private startYearValidator(rules: any, value: string, callback: Function) {
    if (value) {
      callback();
    } else {
      callback(new Error('검색 시작 연도를 입력하세요.'));
    }
  }

  private endYearValidator(rules: any, value: string, callback: Function) {
    if (this.searchQuery.mode === 2) {
      if (!this.searchQuery.year) callback(new Error('검색 시작 연도를 입력하세요.'));
      else callback();
    } else if (this.searchQuery.mode === 3) {
      if (!this.searchQuery.daterange[0] && !this.searchQuery.daterange[1]) callback(new Error('검색 종료 연도를 입력하세요.'));
      else callback();
    } else {
      callback();
    }
  }

  private rules = {
    endYear: [
      { validator: this.endYearValidator, trigger: 'blur' },
    ],
  }

  private getChartDatas() {
    (this.$refs.searchForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        this.searchedQuery = {
          ...this.searchQuery,
        };
        const chartQuery = {
          mode: this.searchQuery.mode,
          year: this.searchQuery.year,
          startDate: this.searchQuery.daterange[0],
          endDate: this.searchQuery.daterange[1],
        };
        this.chartLoading = true;
        this.getTypeChartData(chartQuery);
        this.getAreaChartData(chartQuery);
      }
    });
  }

  private initChart() {
    this.getChartDatas();
  }

  private getTypeChartData(chartQuery: any) {
    this.chartData = {
      ...this.chartData,
      typeLabels: ['기상 재해'],
      typeDatas: [],
    };
    getWeatherTypeChartDatas(chartQuery).then((res) => {
      this.chartLoading = false;
      res.data.forEach((item: any, index: number) => {
        this.chartData.typeDatas.push({
          label: item.weatherType,
          data: [item.cnt],
          backgroundColor: this.brightColors[index] || '#409EFF',
        });
      });
    });
  }

  private getAreaChartData(chartQuery: any) {
    this.chartData = {
      ...this.chartData,
      areaLabels: ['지역'],
      areaDatas: [],
    };
    getAreaChartDatas(chartQuery).then((res) => {
      this.chartLoading = false;
      res.data.forEach((item: any, index: number) => {
        this.chartData.areaDatas.push({
          label: item.area,
          data: [item.cnt],
          backgroundColor: this.brightColors[index] || '#409EFF',
        });
      });
    });
  }

  private pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  }
}
